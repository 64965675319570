'use client'

import { ComponentProps } from 'react'
import { HeroIngredients as HeroIngredientsUI } from 'ui'

import { useGlobalTypography } from '@/store/globalTypographyStore'

export function HeroIngredientsClient({
  ingredients,
  title,
}: Omit<ComponentProps<typeof HeroIngredientsUI>, 'blogPostLinkTitle'>) {
  const { ingredientsPage } = useGlobalTypography()

  return (
    <HeroIngredientsUI
      ingredients={ingredients}
      title={title}
      blogPostLinkTitle={ingredientsPage.blogPostLinkTitle}
      pageTitle={ingredientsPage.importantIngredientsTitle}
    />
  )
}
