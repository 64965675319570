import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export type PreviousPathStoreType = {
  prevPath: string | null
  onChangePrevPath: (path: string) => void
}

export const usePreviousPath = create<PreviousPathStoreType>()(
  persist(
    set => ({
      prevPath: null,
      onChangePrevPath: (prevPath: string) => {
        set({ prevPath })
      },
    }),
    {
      name: 'prevPath',
      storage: createJSONStorage(() => sessionStorage),
      skipHydration: true,
    },
  ),
)
