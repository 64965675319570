import(/* webpackMode: "eager" */ "/app/apps/web/src/cms/blocks/FeatureProducts/ClientWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/cms/blocks/HeroIngredients/HeroIngredientsClient.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/cms/blocks/ProductReviews.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/packages/ui/components/AccordionList/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/components/Button/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/components/DynamicCode.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/components/Icon/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/components/ImageText/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/components/InfoCardList/InfoCard.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/components/PayloadImage.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/components/PayloadVideo.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/modules/BlogPostCard/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/modules/Carousel/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/modules/CommunityTeaser/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/modules/SetTeaser/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/modules/ShopTeaser/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/modules/TeaserSlider/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/modules/Testimonials/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/modules/UniqueSellingPoints/index.tsx")