'use client'

import { usePathname } from 'next/navigation'
import { ComponentProps, useCallback } from 'react'
import { addAlert, FeatureProducts, LoadableComponentProps } from 'ui'

import { logger } from '@/helpers/logger'
import { useCart } from '@/store/cartStore'
import { useGlobalTypography } from '@/store/globalTypographyStore'
import { usePreviousPath } from '@/store/previousPathStore'

type FeatureProductsClientWrapperProps = LoadableComponentProps<
  Omit<
    NonNullable<ComponentProps<typeof FeatureProducts>['data']>,
    'onAddToBasketClick' | 'onCardClick'
  >
>

export default function FeatureProductsClientWrapper({
  loading,
  data,
}: FeatureProductsClientWrapperProps) {
  const cart = useCart()
  const { onChangePrevPath } = usePreviousPath()
  const pathname = usePathname()

  const {
    productPage: { shoppingButtonLabel },
  } = useGlobalTypography()

  const onAddToBasketClick = useCallback(
    async (variantId: string) => {
      try {
        await cart.addToBasket([{ variantId }])
        cart.setIsOpen(true)
      } catch (e) {
        logger.error('FeatureProducts > onAddToBasketClick', e)

        addAlert.error('Fehler beim Hinzufügen eines Produkts')
      }
    },
    [cart],
  )

  return loading ? (
    <FeatureProducts loading />
  ) : (
    <FeatureProducts
      data={{
        ...data,
        products: data.products.map(product => ({
          ...product,
          accessability: { shoppingButtonLabel },
        })),
        onAddToBasketClick,
        onCardClick: () => onChangePrevPath(pathname),
      }}
    />
  )
}
