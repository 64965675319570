'use client'
import { ProductReviewsBlock } from 'cms-types'
import { useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { ProductReviews as ProductReviewsUI, ProductVariant } from 'ui'

import { useGlobalTypography } from '@/store/globalTypographyStore'

type ProductReviewsProps = ProductReviewsBlock & {
  variants: ProductVariant[]
}

export default function ProductReviews(props: ProductReviewsProps) {
  const searchParams = useSearchParams()
  const [currentVariant, setCurrentVariant] = useState<ProductVariant | undefined>(undefined)
  const typography = useGlobalTypography.getState().productPage

  useEffect(() => {
    const variantId = searchParams.get('variant') ?? {}
    const variant = props.variants.find(variant => variant.id === variantId)

    setCurrentVariant(variant)
  }, [props.variants, searchParams])

  return <ProductReviewsUI {...props} selectedVariant={currentVariant} typography={typography} />
}
